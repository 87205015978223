'use client'

import { useIntl } from 'react-intl'
import {
  Text,
  Dialog,
  Button,
  Navigation,
  Cell,
  Card,
  Divider,
  Radio,
  Spacer,
} from '@vinted/web-ui'
import { X24 } from '@vinted/monochrome-icons'

import { ClickableElement } from 'constants/tracking/clickable-elements'

import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'
import ScrollableArea from 'components/ScrollableArea'

import { formatCurrencyAmount } from 'libs/utils/formatString'
import { clickEvent } from 'libs/common/event-tracker/events'
import { CollectionsPlanPricingModel } from 'types/models'
import SeparatedList from 'components/SeparatedList'
import ErrorState from 'components/ErrorState'
import ContentLoader from 'components/ContentLoader'

type Props = {
  isShown: boolean
  plansPricing?: Array<CollectionsPlanPricingModel>
  selectedPlanId?: string
  isErrorHappened: boolean
  isLoading: boolean
  isOrderLoading: boolean
  onNextAction: () => void
  onClose: () => void
  onPlanSelect: (planId: string) => void
  onPageReloadClick: () => void
}

const PlanSelectionModal = ({
  isShown,
  plansPricing,
  selectedPlanId,
  isErrorHappened,
  isLoading,
  isOrderLoading,
  onNextAction,
  onClose,
  onPlanSelect,
  onPageReloadClick,
}: Props) => {
  const { track } = useTracking()
  const translate = useTranslate('featured_collection.multiple.pre_checkout_modal')
  const { locale } = useIntl()

  function handleProceedToCheckoutClick() {
    track(clickEvent({ target: ClickableElement.ProceedToCheckout }))

    onNextAction()
  }

  const handlePlanSelect = (planId: string) => () => {
    onPlanSelect(planId)
  }

  function renderNavigation() {
    return (
      <Navigation
        right={
          <Button
            iconName={X24}
            theme="amplified"
            styling={Button.Styling.Flat}
            testId="collections-plan-selection-close-button"
            onClick={onClose}
            aria={{
              'aria-label': translate('a11y.actions.close'),
            }}
          />
        }
        body={
          <Text
            type={Text.Type.Title}
            width={Text.Width.Parent}
            alignment={Text.Alignment.Center}
            text={translate('title')}
          />
        }
      />
    )
  }

  function renderPlanSuffix(plan: CollectionsPlanPricingModel) {
    return (
      <div className="u-flexbox u-align-items-center">
        {formatCurrencyAmount(plan.totalPrice, locale)}
        <Spacer />
        <Radio
          checked={selectedPlanId === plan.id}
          name={`collections_plan_option_${plan.id}`}
          value={plan.id}
          testId={`collections-plan-option-radio-${plan.id}`}
          aria={{ 'aria-labelledby': `collection_plan_option_${plan.id}-title` }}
        />
      </div>
    )
  }

  function renderContent() {
    if (isErrorHappened && !plansPricing && !isLoading) {
      return <ErrorState onCtaClick={onPageReloadClick} />
    }

    if (isLoading || !plansPricing) {
      return (
        <ContentLoader
          styling={ContentLoader.Styling.Wide}
          size={ContentLoader.Size.Large}
          testId="plans-selection-modal-loader"
        />
      )
    }

    return (
      <>
        <Cell>
          <Text text={translate('body')} />
        </Cell>
        <div className="u-ui-margin-horizontal-large u-ui-margin-bottom-large">
          <Card>
            <div className="u-overflow-hidden">
              <SeparatedList separator={<Divider />}>
                {plansPricing.map(plan => (
                  <Cell
                    title={
                      <Text
                        id={`collection_plan_option_${plan.id}-title`}
                        text={translate(
                          'plan_option.title',
                          { count: plan.maxActiveCount },
                          { count: plan.maxActiveCount },
                        )}
                      />
                    }
                    body={translate(
                      'duration_day_count',
                      { count: plan.effectiveDays },
                      { count: plan.effectiveDays },
                    )}
                    suffix={renderPlanSuffix(plan)}
                    clickable
                    onClick={handlePlanSelect(plan.id)}
                    key={plan.id}
                    testId={`collections-plan-option-${plan.id}`}
                  />
                ))}
              </SeparatedList>
            </div>
          </Card>
        </div>
      </>
    )
  }

  return (
    <Dialog show={isShown} hasScrollableContent testId="plan-selection-modal">
      <div className="u-fill-width">
        {renderNavigation()}
        <Divider />
        <ScrollableArea>{renderContent()}</ScrollableArea>
        {!!plansPricing && (
          <Cell>
            <Button
              text={translate('actions.proceed')}
              styling={Button.Styling.Filled}
              onClick={handleProceedToCheckoutClick}
              isLoading={isOrderLoading}
              testId="collections-plan-selection-proceed-to-checkout-button"
            />
          </Cell>
        )}
      </div>
    </Dialog>
  )
}

export default PlanSelectionModal
