'use client'

import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Dialog } from '@vinted/web-ui'

import useFetch from 'hooks/useFetch'
import useAbTest from 'hooks/useAbTest'
import useTracking from 'hooks/useTracking'
import { navigateToSingleCheckout } from 'libs/utils/checkout'
import { viewSingleCheckoutEvent } from 'libs/common/event-tracker/events'

import { ExtraServiceCheckout } from 'components/ExtraService'
import useMultipleCollections from 'components/FeaturedCollection/useMultipleCollections'
import ContentLoader from 'components/ContentLoader'
import GenericErrorModal from 'pages/SingleCheckout/components/GenericErrorModal'

import { FeaturedCollectionOrderModel } from 'types/models'
import {
  ExtraServiceOrderType,
  ExtraServiceCheckoutModal,
  CheckoutOrderTypeMap,
} from 'constants/extra-service'
import { UiState } from 'constants/ui'
import { AbTestVariant } from 'constants/abtest'

import { actions as extraServiceActions } from 'state/extra-service/slice'

import {
  prepareFeaturedCollectionOrder as prepareFeaturedCollectionOrderRequest,
  getFeaturedCollectionOrder as getFeaturedCollectionOrderRequest,
  getCollectionsPlansPricing,
  initiateSingleCheckout,
} from 'data/api'
import {
  transformCollectionsPlansPricingResponse,
  transformFeaturedCollectionOrderResponse,
} from 'data/api/transformers/response'

import OrderConfirmationModal from './OrderConfirmationModal'
import PlanSelectionModal from './PlanSelectionModal'
import ErrorModal from './ErrorModal'

type Props = {
  show: boolean
  /** Only used in single collection flow. Not needed for multiple collections. */
  featuredCollectionId?: number
  onSuccess: () => void
  onClose: () => void
}

const FeaturedCollectionCheckout = ({ show, onSuccess, onClose, featuredCollectionId }: Props) => {
  const { track } = useTracking()
  const dispatch = useDispatch()
  const isMultipleCollectionsEnabled = useMultipleCollections()
  const isUnifiedCheckoutEnabled =
    useAbTest({ abTestName: 'single_vas_checkout_web' })?.variant === AbTestVariant.On

  const [activeModal, setActiveModal] = useState<ExtraServiceCheckoutModal>(
    ExtraServiceCheckoutModal.None,
  )
  const [order, setOrder] = useState<FeaturedCollectionOrderModel>()
  const [selectedPlanId, setSelectedPlanId] = useState<string>()
  const [uiState, setUiState] = useState(UiState.Idle)

  const { fetch: prepareFeaturedCollectionOrder, isLoading: isOrderPrepareLoading } = useFetch(
    prepareFeaturedCollectionOrderRequest,
    transformFeaturedCollectionOrderResponse,
  )
  const { fetch: getFeaturedCollectionOrder, isLoading: isOrderGetLoading } = useFetch(
    getFeaturedCollectionOrderRequest,
    transformFeaturedCollectionOrderResponse,
  )

  const {
    fetch: fetchPlansPricing,
    isLoading: isPlansPricingLoading,
    transformedData: plansPricing,
    error: plansPricingError,
  } = useFetch(getCollectionsPlansPricing, transformCollectionsPlansPricingResponse)

  const isOrderPrepared = !!order
  const isOrderPreparationInProgress = isOrderPrepareLoading || isOrderGetLoading
  const isPlanChanged =
    isMultipleCollectionsEnabled &&
    !!order?.planPricingId &&
    selectedPlanId !== order?.planPricingId

  const fetchOrder = useCallback(async () => {
    const { transformedData, error } = await prepareFeaturedCollectionOrder({
      planId: selectedPlanId,
    })

    if (error) {
      setActiveModal(ExtraServiceCheckoutModal.Error)

      return
    }

    setOrder(transformedData)
    setActiveModal(ExtraServiceCheckoutModal.Checkout)
  }, [prepareFeaturedCollectionOrder, selectedPlanId])

  useEffect(() => {
    if (activeModal !== ExtraServiceCheckoutModal.PreCheckout) return
    if (!isMultipleCollectionsEnabled) return
    if (!!plansPricing?.collectionsPricing.length || isPlansPricingLoading || plansPricingError)
      return

    fetchPlansPricing()
  }, [
    activeModal,
    isMultipleCollectionsEnabled,
    plansPricing,
    isPlansPricingLoading,
    fetchPlansPricing,
    plansPricingError,
  ])

  useEffect(() => {
    if (!show) return
    if (!isMultipleCollectionsEnabled) return
    if (selectedPlanId) return
    if (!plansPricing?.collectionsPricing.length) return

    const defaultPlan =
      plansPricing.collectionsPricing.find(plan => plan.default) ||
      plansPricing.collectionsPricing[0]!

    setSelectedPlanId(defaultPlan.id)
  }, [isMultipleCollectionsEnabled, plansPricing, selectedPlanId, show])

  useEffect(() => {
    if (!show) return
    if (activeModal !== ExtraServiceCheckoutModal.None) return
    if (isMultipleCollectionsEnabled) {
      setActiveModal(ExtraServiceCheckoutModal.PreCheckout)

      return
    }

    if (isOrderPrepared) {
      setActiveModal(ExtraServiceCheckoutModal.Checkout)

      return
    }

    fetchOrder()
  }, [activeModal, fetchOrder, isMultipleCollectionsEnabled, isOrderPrepared, show])

  useEffect(() => {
    if (!isUnifiedCheckoutEnabled) return
    if (activeModal !== ExtraServiceCheckoutModal.Checkout) return

    const orderId = order?.id
    const orderType = CheckoutOrderTypeMap[ExtraServiceOrderType.FeaturedCollection]
    if (!orderId) return

    track(
      viewSingleCheckoutEvent({
        checkoutId: null,
        orderType,
        orderId: orderId.toString(),
      }),
    )

    const fetchCheckoutId = async () => {
      setUiState(UiState.Pending)

      const response = await initiateSingleCheckout({
        id: orderId.toString(),
        type: orderType,
      })

      if ('errors' in response) {
        setUiState(UiState.Failure)

        setActiveModal(ExtraServiceCheckoutModal.GenericErrorModal)

        return
      }

      navigateToSingleCheckout(response.checkout.id, orderId, orderType)
    }

    fetchCheckoutId()
  }, [order?.id, activeModal, isUnifiedCheckoutEnabled, track])

  function updateSelectedPlan(planId: string) {
    setSelectedPlanId(planId)
  }

  function closeCheckoutModal() {
    setActiveModal(ExtraServiceCheckoutModal.None)
    dispatch(extraServiceActions.cancelOrderPaymentAuth())
    onClose()
  }

  function finilizeCheckout(isCheckoutSuccess: boolean) {
    closeCheckoutModal()

    if (isCheckoutSuccess) {
      onSuccess()
    }
  }

  async function handleCheckoutInitAfterCardAuth(orderId: number) {
    const { transformedData } = await getFeaturedCollectionOrder(orderId)

    setOrder(transformedData)
    setSelectedPlanId(transformedData?.planPricingId)

    setActiveModal(ExtraServiceCheckoutModal.Checkout)
  }

  function handlePreCheckoutConfirm() {
    const isNewOrderNeeded = !isOrderPrepared || isPlanChanged

    if (!isNewOrderNeeded) {
      setActiveModal(ExtraServiceCheckoutModal.Checkout)

      return
    }

    fetchOrder()
  }

  function handleCheckoutModalBack() {
    if (!isMultipleCollectionsEnabled) {
      closeCheckoutModal()

      return
    }

    setActiveModal(ExtraServiceCheckoutModal.PreCheckout)
  }

  function handleReloadPage() {
    fetchPlansPricing()
  }

  if (uiState === UiState.Pending) {
    return (
      <Dialog show>
        <ContentLoader testId="featured-collection-checkout-loader" />
      </Dialog>
    )
  }

  return (
    <>
      <PlanSelectionModal
        plansPricing={plansPricing?.collectionsPricing}
        selectedPlanId={selectedPlanId}
        isErrorHappened={!!plansPricingError}
        isShown={activeModal === ExtraServiceCheckoutModal.PreCheckout}
        isLoading={isPlansPricingLoading}
        isOrderLoading={isOrderPreparationInProgress}
        onNextAction={handlePreCheckoutConfirm}
        onClose={closeCheckoutModal}
        onPlanSelect={updateSelectedPlan}
        onPageReloadClick={handleReloadPage}
      />
      <ErrorModal
        isShown={activeModal === ExtraServiceCheckoutModal.Error}
        onClose={closeCheckoutModal}
      />
      <ExtraServiceCheckout
        show={activeModal === ExtraServiceCheckoutModal.Checkout && !isUnifiedCheckoutEnabled}
        orderType={ExtraServiceOrderType.FeaturedCollection}
        orderId={order?.id}
        orderPayable={order?.payable}
        addCardSourceParams={{
          featuredCollectionId,
          type: ExtraServiceOrderType.FeaturedCollection,
        }}
        onBack={handleCheckoutModalBack}
        onCheckoutFinish={finilizeCheckout}
        onInitStartAfterCardAuth={handleCheckoutInitAfterCardAuth}
        orderConfirmationModal={props => (
          <OrderConfirmationModal
            show={props.show}
            order={order}
            onBack={props.onBack}
            onConfirmOrder={props.onConfirmOrder}
            onShowSalesTaxModal={props.onShowSalesTaxModal}
            onPaymentMethodSelect={props.onPaymentMethodSelect}
            isMultipleCollectionsEnabled={isMultipleCollectionsEnabled}
          />
        )}
      />
      <GenericErrorModal
        isShown={activeModal === ExtraServiceCheckoutModal.GenericErrorModal}
        onClose={closeCheckoutModal}
      />
    </>
  )
}

export default FeaturedCollectionCheckout
