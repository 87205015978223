'use client'

import { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Text, Spacer } from '@vinted/web-ui'

import { SelectableListItem, FormattedMessageValues } from 'types/components'
import { SortByOption } from 'constants/filter'

import SelectableItemList from 'components/SelectableItemList'
import FilterDropdown from 'components/FilterDropdown'

type SortItem = SelectableListItem<SortByOption, SortByOption>

type Props = {
  selected: SortByOption
  onItemClick: (value: SortByOption) => void
  onDropdownOpen?: () => void
}

class SortFilter extends Component<Props> {
  static defaultProps = {
    selected: SortByOption.Relevance,
  }

  // TODO: convert into functional component
  // eslint-disable-next-line class-methods-use-this
  translate = (id: string, values?: FormattedMessageValues) => (
    <FormattedMessage id={`user.items.filters.sort.${id}`} values={values} />
  )

  handleItemClick = (item: SortItem) => {
    const { onItemClick } = this.props

    onItemClick(item.value)
  }

  handleOnDropdownOpen = () => {
    this.props.onDropdownOpen?.()
  }

  renderDropdown() {
    const items = Object.values(SortByOption).map(option => ({
      id: option,
      title: this.translate(`options.${option}`),
      value: option,
    }))

    const { selected } = this.props

    return (
      <div className="u-ui-padding-vertical-x-small">
        <SelectableItemList<SortByOption, SortByOption>
          name="sort_by"
          items={items}
          selected={[selected]}
          onItemClick={this.handleItemClick}
        />
      </div>
    )
  }

  render() {
    return (
      <div className="u-flexbox u-align-items-center">
        <Text theme="amplified" type={Text.Type.Subtitle} text={this.translate('name')} />
        <Spacer orientation={Spacer.Orientation.Vertical} />
        <FilterDropdown
          title={this.translate(`options.${this.props.selected}`)}
          placement="bottom-end"
          closeOnClick
          onOpen={this.handleOnDropdownOpen}
          testId="sort-filter-dropdown"
        >
          {this.renderDropdown()}
        </FilterDropdown>
      </div>
    )
  }
}

export default SortFilter
