'use client'

import { useIntl } from 'react-intl'
import { Button, Cell, Icon, Spacer, Text } from '@vinted/web-ui'
import { InfoCircle16 } from '@vinted/monochrome-icons'

import useTranslate from 'hooks/useTranslate'

import { formatCurrencyAmount } from 'libs/utils/formatString'
import { FeaturedCollectionOrderModel } from 'types/models'
import SeparatedList from 'components/SeparatedList'

type Props = {
  order: FeaturedCollectionOrderModel
  isMultipleCollectionsEnabled: boolean
  onShowSalesTaxModal: () => void
}

const OrderSummary = ({ order, isMultipleCollectionsEnabled, onShowSalesTaxModal }: Props) => {
  const translate = useTranslate(
    isMultipleCollectionsEnabled
      ? 'featured_collection.multiple.order_confirmation_modal.order_summary'
      : 'featured_collection.order_confirmation_modal.order_summary',
  )

  const { locale } = useIntl()

  const {
    total,
    payable,
    effectiveDays,
    maxActiveCollectionsCount = 1,
    salesTax,
    isTaxCovered,
  } = order

  function renderPrice() {
    const dayCount = translate(
      'price_day_count',
      { count: effectiveDays },
      { count: effectiveDays },
    )

    const planPrice = isMultipleCollectionsEnabled
      ? translate(
          'plan_price',
          { count: maxActiveCollectionsCount },
          { count: maxActiveCollectionsCount },
        )
      : translate('price')

    return (
      <Cell styling={Cell.Styling.Tight} suffix={formatCurrencyAmount(total, locale)}>
        <Text text={`${planPrice} ${dayCount}`} />
      </Cell>
    )
  }

  function renderSalesTax() {
    if (!salesTax?.amount) return null

    return (
      <>
        <Spacer size={Spacer.Size.XLarge} />
        <Cell
          styling={Cell.Styling.Tight}
          suffix={`${formatCurrencyAmount(salesTax, locale)}`}
          testId="collection-order-summary-sales-tax"
        >
          <div className="u-flexbox u-align-items-center">
            <Text
              text={translate('sales_tax')}
              testId="collection-order-summary-sales-tax--title"
            />
            <Button
              icon={
                <Icon
                  name={InfoCircle16}
                  color={Icon.Color.GreyscaleLevel3}
                  aria={{
                    'aria-label': translate('a11y.sales_tax_info'),
                  }}
                />
              }
              inline
              size={Button.Size.Small}
              styling={Button.Styling.Flat}
              onClick={onShowSalesTaxModal}
              testId="collection-order-summary-sales-tax--info-circle"
            />
          </div>
        </Cell>
        {isTaxCovered && (
          <Cell
            styling={Cell.Styling.Tight}
            body={
              <Text
                text={translate('sales_tax_covered_by_vinted')}
                highlightTheme="amplified"
                type={Text.Type.Subtitle}
                testId="collection-order-summary-sales-tax--covered-by-vinted"
              />
            }
          />
        )}
      </>
    )
  }

  function renderTotal() {
    return (
      <>
        <Spacer size={Spacer.Size.XLarge} />
        <Cell
          styling={Cell.Styling.Tight}
          title={translate('total')}
          suffix={<Text type={Text.Type.Title} text={formatCurrencyAmount(payable, locale)} />}
        />
      </>
    )
  }

  return (
    <Cell>
      <SeparatedList separator={<Spacer size={Spacer.Size.Small} />}>
        {renderPrice()}
        {renderSalesTax()}
        {renderTotal()}
      </SeparatedList>
    </Cell>
  )
}

export default OrderSummary
